import {
	ConfigCssProperties,
	ConfigEnabledModules,
	ConfigEndpoints,
	ConfigIconPaths,
	ConfigPage,
	ConfigContent,
	ConfigDeploy,
	ConfigOneWeb,
	WysiwygConfig,
	DeactivateLayerConfig,
} from '@typings/config-types';
import { AppPages } from './src/constants';

export const deployConfig: ConfigDeploy = {
	client: 'ks',
	stage: 'prod',
	zone: 'Z0016664YH0UFLAAWYSW',
	fqdn: 'cms.kandrive.gov',
	cert: 'arn:aws:acm:us-east-1:215140708333:certificate/7aa27435-cfeb-45cf-90db-8b62b31204d4',
	logBucket: 'crc-logs-s3.s3.amazonaws.com',
	region1: 'us-west-2',
};

const ENDPOINT_BASE = 'https://kstg.carsprogram.org';

export const apiConfig: ConfigEndpoints = {
	graphql: `${ENDPOINT_BASE}/cms_v1/api/graphql`,
	cameras: `${ENDPOINT_BASE}/cameras_v1/api/cameras`,
	signs: `${ENDPOINT_BASE}/signs_v1/api/signs`,
	deactivate: `${ENDPOINT_BASE}/cms_v1/api/cms/configurations`,
};

export const cssProperties: ConfigCssProperties = {
	// Configurable Colors
	// '--silver': '79, 212, 180',
};

export const iconConfig: ConfigIconPaths = {
	'alert-triangle': '/images/icon-alert-triangle.svg',
	'add': '/images/icon-add.svg',
	'campaigns-full': '/images/icon-campaigns.svg',
	'campaigns': '/images/sprites.svg#campaign',
	'close': '/images/sprites.svg#close',
	'close-icon': '/images/icon-close.svg',
	'component-gallery': '/images/icon-component-gallery.svg',
	'component-list': '/images/icon-component-list.svg',
	'dashboards-full': '/images/icon-dashboards.svg',
	'dashboards': '/images/sprites.svg#dashboard',
	'filter': '/images/sprites.svg#filter',
	'filter-full': '/images/icon-column-filter.svg',
	'floodgate': '/images/icon-floodgate.svg',
	'floodgate-low': '/images/icon-fg-low.svg',
	'floodgate-med': '/images/icon-fg-med.svg',
	'floodgate-high': '/images/icon-fg-high.svg',
	'layer': '/images/icon-layers.svg',
	'grab-handle': '/images/icon-grab-handle.svg',
	'help': '/images/sprites.svg#help',
	'help-full': '/images/icon-help.svg',
	'delete': '/images/sprites.svg#delete',
	'delete-full': '/images/icon-delete.svg',
	'edit': '/images/sprites.svg#edit',
	'expand-arrow': '/images/sprites.svg#arrow',
	'messages-full': '/images/icon-messages.svg',
	'messages': '/images/sprites.svg#message',
	'plows-full': '/images/icon-plow.svg',
	'plows': '/images/sprites.svg#plow',
	'splash-media': '/images/splash-media.svg',
	'upload-image': '/images/icon-upload-image.svg',
	'visible-full': '/images/icon-visible.svg',
	'visible-container-full': '/images/icon-visible-container.svg',
	'hidden-full': '/images/icon-hidden.svg',
	'hidden-container-full': '/images/icon-hidden-container.svg',
	'visible': '/images/sprites.svg#visible',
	'visible-container': '/images/sprites.svg#visible-container',
	'hidden': '/images/sprites.svg#hidden',
	'hidden-container': '/images/sprites.svg#hidden-container',
};

export const pageConfig: ConfigPage = {
	pageTitle: 'Kansas Media',
	pageDesc: 'CMS for One Web Announcements and Campaigns',
	loginImageUrl: '/images/splash-media.svg',
	logos: [],
	regionalLogos: [],
	analyticsTag: 'WHOKNOWS',
};

export const contentConfig: ConfigContent = {
	enableDisplayLocationControls: false,
	campaignImageHeight: 200,
	campaignImageWidth: 1080,
};

// Config values that should match the cooresponding one-web value
export const oneWebConfig: ConfigOneWeb = {
	// cameraConfig.hotCamera.useCameraIdToSearchForDefaultHotCameras
	useCameraIdToSearchForCameras: true,
};

export const enabledModulesConfig: ConfigEnabledModules = {
	defaultPage: AppPages.MESSAGES,
	messages: true,
	campaigns: true,
	plows: false,
	dashboards: true,
	helpPages: true,
	floodgates: true,
	floodgatePhoneOptions: true,
	deactivateLayers: false,
};

export const wysiwygConfig: WysiwygConfig = {
	tinyMceToolbar: `undo redo | h1 h2 h3 | link | bold italic underline strikethrough | superscript subscript |
	alignleft aligncenter alignright alignjustify |
	bullist numlist | outdent indent | removeformat | image`,
	tinyMceApiKey: 'ofjdqjl0tzqp5tfv3zywt5utwvvxrc2s619bjuk72h1px0ww',
};

export const deactivateLayerConfig: DeactivateLayerConfig = {
	layers: [
		{
			friendlyName: 'Incidents',
			slug: 'roadReports',
		},
		{
			friendlyName: 'Construction',
			slug: 'constructionReports',
		},
		{
			friendlyName: 'Future Construction',
			slug: 'future',
		},
		{
			friendlyName: 'Electronic Signs',
			slug: 'electronicSigns',
		},
	],
};
